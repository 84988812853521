import React, { useState, useEffect } from "react";
import { AppLayout } from "../../../layouts/AppLayout";
import "./onlineCustomers.css";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import {
  depositCashierCash,
  getUserInfo,
  withdrawalCashierCash,
  searchUserByNumber,
  verifyCode,
} from "../../Common/repository";
import { setOnlineCustomer } from "../../../store/OnlineCustomer/action";
import { setBalance } from "../../../store/Cashbalance/action";

export const OnlineCustomers = () => {
  let dispatch = useDispatch();
  const OnlineCustomer = useSelector(
    (state) => state?.OnlineCustomer?.onlineCustomers ?? []
  );
  const [code, set_code] = useState(null);
  const [pin, setPin] = useState(null);
  const [openModel, setModelOpen] = useState(false);
  const [phone, setphone] = useState(OnlineCustomer?.phone_number);
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);
  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
      // backgroundColor: "#272727",
      color: "black",
      padding: "10px",
      height: "auto",
    },
  };
  useEffect(() => {
    setphone(OnlineCustomer?.phone_number);
  }, [OnlineCustomer]);

  const _handleModalOpen = (amount) => {
    if (amount) {
      sendCode();
      setModelOpen(true);
    } else {
      toastr.error("Please Enter Valid Amount");
    }
  };
  const closeModal = (idElement) => {
    setModelOpen(false);
    $("#" + idElement).val("");
  };
  const sendCode = () => {
    setRunTimer((t) => !t);
    const data = {
      phone_number: phone,
      type: "forgotpin",
    };

    verifyCode(data)
      .then((response) => {
        setPin(response?.data?.pin);
        toastr.success("Code sent successfully");
      })
      .catch(function (response) {
        toastr.error(response?.response?.data?.error);
      });
  };
  const searchByNumber = () => {
    if (phone) {
      searchUserByNumber(phone).then((response) => {
        if (response?.data?.error) {
          dispatch(setOnlineCustomer([]));
          toastr.error(response?.data?.error);
        } else {
          dispatch(setOnlineCustomer(response?.data?.data));
        }
      });
    }
  };

  const _handleSubmitWithdraw = (id, amount, idElement) => {
    setModelOpen(false);
    console.log(id, amount, idElement);
    // if (pin == parseInt(code)) {
      $("#" + idElement).val("");
      withdrawal(id, amount, idElement,pin,parseInt(code));
    // } else {
    //   toastr.success("Incorrect pin");
    // }
  };
  const deposit = (id, amount, idElement) => {
    try {
      var loggedInUser = JSON.parse(localStorage.getItem("users"))?.id;
      depositCashierCash(id, loggedInUser, { amount: amount })
        .then((res) => {
          toastr.success(res?.data?.message);
          $("#" + idElement).val("");
          getUserInfo().then((res) => {
            dispatch(setBalance(res?.data?.data?.cash_balance));
          });
          searchByNumber();
        })
        .catch((error) => {
          toastr.error(error?.response?.data?.message);
        });
    } catch (error) {
      toastr.error("User not found");
    }
  };

  const withdrawal = (id, amount, idElement,pin,code) => {
    try {
      var loggedInUser = JSON.parse(localStorage.getItem("users"))?.id;
      withdrawalCashierCash(id, loggedInUser, { amount: amount,pin:pin,code:code})
        .then((res) => {
          toastr.success(res?.data?.message);
          $("#" + idElement).val("");
          getUserInfo().then((res) => {
            dispatch(setBalance(res?.data?.data?.cash_balance));
          });
          searchByNumber();
        })
        .catch((error) => {
          toastr.error(error?.response?.data?.message);
        });
    } catch (error) {
      toastr.error("User not found");
    }
  };

  return (
    <AppLayout>
      {OnlineCustomer ? (
        <div className="row ">
          <div className="col-lg-9 row-main p-4">
            <div className="card">
              <div className="card-title">
                <div className="card-title mb-4">User Details</div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className="col-form-label">User ID</label>
                </div>
                <div className="col">
                  <p className="text-muted fw-medium col-form-label">
                    {OnlineCustomer?.id}
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className="col-form-label">Mobile Number</label>
                </div>
                <div className="col">
                  <p className="text-muted fw-medium col-form-label">
                    {OnlineCustomer?.phone_number}
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className="col-form-label">User Name</label>
                </div>
                <div className="col">
                  <p className="text-muted fw-medium col-form-label">
                    {OnlineCustomer?.first_name} {OnlineCustomer?.last_name}
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className="col-form-label">Cash Balance</label>
                </div>
                <div className="col">
                  <p className="text-muted fw-medium col-form-label">
                    {OnlineCustomer?.cash_balance}
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className="col-form-label">Status</label>
                </div>
                <div className="col">
                  <p className="text-muted fw-medium col-form-label">
                    {OnlineCustomer?.user_status == 0
                      ? "Inactive"
                      : OnlineCustomer?.user_status == 1
                      ? "Active"
                      : ""}
                  </p>
                </div>
              </div>
              {OnlineCustomer?.id && OnlineCustomer?.user_status == 1 ? (
                <div className="row mb-3">
                  <div className="col">
                    <label className="col-form-label">Action</label>
                  </div>
                  <div className="col">
                    <div
                      className="input-group"
                      style={{ width: "62%", marginBottom: "7px" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id={`inputGroupSendMoney${OnlineCustomer?.id}`}
                        aria-describedby="inputGroupSendMoney"
                        placeholder="Deposit Money"
                        style={{ fontSize: "inherit" }}
                      />
                      <button
                        className="btn btn-success"
                        type="button"
                        id="inputGroupSendMoney"
                        onClick={(event) => {
                          deposit(
                            OnlineCustomer?.id,
                            $(
                              `#inputGroupSendMoney${OnlineCustomer?.id}`
                            ).val(),
                            `inputGroupSendMoney${OnlineCustomer?.id}`
                          );
                        }}
                        style={{ fontSize: "inherit", zIndex: 0 }}
                      >
                        Deposit Money
                      </button>
                    </div>
                    {OnlineCustomer?.cash_balance &&
                    OnlineCustomer?.cash_balance != 0 ? (
                      <div
                        className="input-group"
                        style={{ width: "62%", marginBottom: "7px" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          id={`inputGroupCollectMoney${OnlineCustomer?.id}`}
                          aria-describedby="inputGroupCollectMoney"
                          placeholder="Withdraw Money"
                          style={{ fontSize: "inherit" }}
                        />
                        <button
                          type="submit"
                          className="btn btn-danger"
                          id="inputGroupCollectMoney"
                          // onClick={() => {
                          //   withdrawal(
                          //     OnlineCustomer?.id,
                          //     $(
                          //       `#inputGroupCollectMoney${OnlineCustomer?.id}`
                          //     ).val(),
                          //     `inputGroupCollectMoney${OnlineCustomer?.id}`
                          //   );
                          // }}
                          onClick={() => {
                            _handleModalOpen(
                              $(
                                `#inputGroupCollectMoney${OnlineCustomer?.id}`
                              ).val()
                            );
                          }}
                          style={{ fontSize: "inherit", zIndex: 0 }}
                        >
                          Withdraw Money
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row modal-pop-up">
        <ReactModal
          isOpen={openModel}
          style={customModalStyles}
          appElement={document.getElementById("root")}
          // className="modal-content-react"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">OTP Verification</div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => {
                  closeModal(`inputGroupCollectMoney${OnlineCustomer?.id}`);
                }}
              ></button>
            </div>
            <div className="modal-body" style={{ padding: "1rem" }}>
              <div className="BLM-form-group">
                <div className="BLM-form-label">Enter OTP Here:</div>
                <div
                  className="BLM-form-control-group"
                  id="regErrorMessageClass"
                >
                  <div className="BLM-form-control">
                    <input
                      type="text"
                      id="code"
                      placeholder="Enter OTP"
                      maxLength={6}
                      required
                      onChange={(e) => {
                        set_code(e.target.value);
                      }}
                      // value={code}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                style={{ padding: "8px 17px", margin: "5px" }}
                onClick={() => {
                  _handleSubmitWithdraw(
                    OnlineCustomer?.id,
                    $(`#inputGroupCollectMoney${OnlineCustomer?.id}`).val(),
                    `inputGroupCollectMoney${OnlineCustomer?.id}`
                  );
                }}
              >
                Continue
              </button>
              <button
                type="button"
                className="btn btn-danger"
                style={{ padding: "8px 17px", margin: "5px" }}
                onClick={() => {
                  closeModal(`inputGroupCollectMoney${OnlineCustomer?.id}`);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    </AppLayout>
  );
};
